.rti--tag > button:focus,
.rti--tag > button:focus-visible {
  outline: none;
}

.invalid-file {
  border: 1px solid #ea5455;
  border-radius: 8px;
  height: 70px !important;
}

.mark-as-offer:hover {
  background-color: #7367f012;
}

.rounded-check {
  width: 20px;
  height: 18px;
  background-color: white;
  border-radius: 20%;
  border: 1px solid #ddd;
  outline: none;
  cursor: pointer;
}

.tooltip-inner {
  background-color: white;
  color: black !important;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 4px !important;
}

.arrow::before {
  border-bottom-color: #f1f1f1 !important;
}

.arrow {
  margin-top: 4px !important;
}
