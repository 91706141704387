.semi-dark-layout {
  /************ Main Menu **************/
}

.semi-dark-layout .main-menu {
  background-color : #283046;
}

.semi-dark-layout .main-menu .shadow-bottom {
  background : -webkit-linear-gradient(top, #283046 44%, rgba(40, 48, 70, 0.51) 73%, rgba(40, 48, 70, 0));
  background :         linear-gradient(180deg, #283046 44%, rgba(40, 48, 70, 0.51) 73%, rgba(40, 48, 70, 0));
}

.semi-dark-layout .main-menu:not(.expanded) .navigation .sidebar-group-active a {
  background-color : #161D31;
}

.semi-dark-layout .main-menu-content .navigation-main {
  background-color : #283046;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item i {
  color : #B4B7BD;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item.open a {
  background-color : #161D31;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item a:after, .semi-dark-layout .main-menu-content .navigation-main .nav-item a {
  color : #B4B7BD;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content {
  background-color : #283046;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content .active .menu-item {
  color : #FFFFFF;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content li:not(.active) a {
  background-color : #283046;
}

.semi-dark-layout .main-menu-content .navigation-main .active .menu-title, .semi-dark-layout .main-menu-content .navigation-main .active i {
  color : #FFFFFF;
}

.semi-dark-layout .main-menu-content .navigation-main .sidebar-group-active a {
  background : #283046;
  border-radius : 4px;
}

.semi-dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content {
  background-color : #283046;
}

.semi-dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content .active {
  z-index : 1;
}

.semi-dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content .active a {
  background-color : transparent;
}

.semi-dark-layout.menu-collapsed .main-menu:not(.expanded) .navigation-main li.active a {
  background : #161D31 !important;
}