.button-arrow {
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  padding: 8px 14px;
  background-color: none !important;
  background: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.button-number {
  border: 0px;
  border-radius: 8px;
  padding: 8px 14px;
  font-size: 14px !important;
  font-weight: 500 !important;
}
