.main-menu-content {
  background-color: black !important;
}
.navigation.navigation-main {
  background-color: black !important;
}

.menu-title {
  color: white !important;
  font-size: 13px;
  font-weight: 500;
}

.nav-item.active > a {
  background-color: #ffff00 !important;
  background: #ffff00 !important;
}

.nav-item.active > a > span {
  color: black !important;
}
