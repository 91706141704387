/*========================================================
        DARK LAYOUT
=========================================================*/
#toast-container > div {
  opacity : 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter : alpha(opacity=90);
  max-width : none;
}

#toast-container > div:hover {
  box-shadow : 2px 2px 10px 0 rgba(0, 0, 0, 0.75);
}

.toast {
  background-color : #7367F0;
}

.toast-success {
  background-color : #28C76F;
}

.toast-error {
  background-color : #EA5455;
}

.toast-info {
  background-color : #00CFE8;
}

.toast-warning {
  background-color : #FF9F43;
}