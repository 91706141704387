.border-25 {
  border-radius: 25px;
}
.bg-black {
  background-color: #000000 !important;
}
.btn-snapchat {
  background-color: black !important;
  color: white !important;
  padding: 8px 16px;
}
.fs-12 {
  font-size: 12px;
}
.back-img {
  height: fit-content;
  /* background-image: url(../../../app-assets/images/pages/image.png) no-repeat center center fixed; */
  /* background-size: 100% 100%; */
  width: 100%;
  background: url(../../app-assets/images/pages/Image.png) no-repeat center
    center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* html .content.app-content {
  padding: calc(2rem + 0.45rem + 1.3rem) 0rem 0;
} */
.h1-center {
  /* position: absolute; */
  padding-top: 250px;
  /* left: 50%; */
  /* bottom: 0; */
  width: 100%;
  text-align: center;
  color: white;
}
.text-white {
  color: #ffffff;
}
.white-border {
  border: 1.5px solid white;
  border-radius: 15px;
}
.homepage-navbar-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
}

.toppadding {
  top: 52px !important;
}
.black-portion-common-ptag {
  font-size: 22px;
  text-align: center;
}

.black-portion-common-h3tag {
  text-align: center;
  font-size: 2.6rem;
}
