.header-navbar.floating-nav {
  position: fixed;
  right: 0;
  margin: 0rem;
  width: calc(100vw - (100vw - 100%));
  border-radius: 25px;
  border-radius: 25px;
  z-index: 1032;
}
.card {
  margin-bottom: 1rem !important;
}
select.form-control:not([multiple="multiple"]) {
  padding-right: 2.5rem !important;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  width: 245px !important;
}
.nav-link.active {
  border-color: #000 !important;
  box-shadow: 0 4px 18px -4px rgb(115 103 240 / 65%);
  border-radius: 25px;
  color: #fff !important;
  background-color: #000 !important;
}
.nav-pills > .nav-item > .nav-link {
  font-size: 14px;
  font-weight: 500;
}
.table .card-header-bg th {
  background-color: #ffffff !important;
  color: #000000;
}

th {
  background-color: #ffffff;
  color: #595959;
  font-size: 13px;
  line-height: 18.84px;
  letter-spacing: 0.1px;
  font-weight: 500;
  border: 0px;
  border-bottom: 1px #cccccc;
  padding-bottom: 8px;
}

td {
  display: table-cell;
  vertical-align: middle !important;
}

td > span {
  font-size: 14px;
  font-weight: 400;
  color: #595959;
  line-height: 20.02px;
  letter-spacing: 0.17px;
}

.badge-width-25 {
  width: 20px;
  height: 20px;
}
.badge-snapchat {
  background-color: #f8ec86;
}
.badge-snapchat-secondary {
  background-color: #b8b8b8;
}
.btn-snapchat-danger {
  background-color: #ff7575;
  color: black;
}
.table thead th,
.table tfoot th {
  vertical-align: top;
  text-transform: capitalize;
}

.no-padding {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.hide-btn-snap {
  display: none;
  visibility: none;
}

.btn-snapchat {
  background-color: #000;
  color: #fff;
  border-radius: 24px;
  padding: 8px 16px;
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 500;
}

.form-label-style {
  font-size: 14px;
  font-weight: 500;
  color: #404040;
}

.disabled-btn-main-wrapper {
  color: #acb0b5;
  border-color: #e3e3e3;
  border-style: solid;
  cursor: not-allowed;
  background: #ebebeb
    repeating-linear-gradient(
      45deg,
      #ebebeb,
      #ebebeb 2px,
      #f2f2f2 2px,
      #f2f2f2 8px
    );
}

.offer-delete-btn {
  display: flex;
  position: absolute;
  top: 4%;
  cursor: pointer;
  right: 5px;
}
.offer-launchdate {
  margin-left: 5px;
  cursor: pointer;
}

.offer-orange-btn-wrapper {
  background-color: #ff7600;
}
.guidence-text-wrapper {
  position: absolute;
  width: 50%;
  margin-top: 14px;
  margin-bottom: 17px;
  font-size: 10px;
}

.btn-download {
  background: #000 !important;
  color: white !important;
  border: 0px solid;
  border-radius: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
}

.form-date {
  border: 0px !important;
  background-color: none !important;
  background: none !important;
  color: #595959 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.badge-pill {
  border-radius: 16px;
  padding: 2px 8px 2px 6px;
  display: flex;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
}

.styled-select > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='darkgrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 8px;
  padding-right: 3.5rem;
}

.styled-select > button:hover > * {
  color: black !important;
}

.styled-select > button:focus-visible,
.styled-select > button:focus {
  outline: none !important;
}

.styled-select > select:focus-visible,
.styled-select > select:focus {
  outline: none !important;
  border-color: #000 !important;
}

.clear-icon {
  color: #6c6c6c !important;
}

.close-button:hover {
  color: black;
}
