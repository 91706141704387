html {
  font-size: 14px;
  height: 100%;
  letter-spacing: 0.01rem;
  /*
    * Blank page
    */
}

html body {
  height: 100%;
  background-color: #f8f8f8;
  direction: ltr;
}

html body p {
  line-height: 1.5rem;
}

html .content {
  padding: 0;
  position: relative;
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  min-height: calc(100% - 3.35rem);
  margin-left: 260px;
}

/* html .content.app-content {  // changes made by kumarjeet poddar
  padding : calc(2rem + 4.45rem + 1.3rem) 2rem 0;
} */

html .content.app-content.show-overlay .content-overlay {
  z-index: 10;
  opacity: 1;
}

html
  .content.app-content.show-overlay
  .content-overlay
  ~ .header-navbar-shadow {
  display: none;
}

html .content.app-content .content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 41, 47, 0.5);
  cursor: pointer;
  -webkit-transition: all 0.7s, z-index 0s;
  transition: all 0.7s, z-index 0s;
  z-index: -1;
}

html .content.app-content .content-area-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

html .content.app-content .content-area-wrapper .content-wrapper,
html .content.app-content .content-area-wrapper .content-body {
  height: 100%;
}

html .content .body-content-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

html .content .body-content-overlay.show {
  visibility: visible;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
  background-color: rgba(34, 41, 47, 0.2);
  border-radius: 0.1785rem;
}

html .content .content-wrapper .content-header-title {
  font-weight: 500;
  color: #636363;
  padding-right: 1rem;
  border-right: 1px solid #d6dce1;
}

html .content .content-wrapper .content-header-right .dropdown-toggle::after {
  display: none;
}

html .content .content-wrapper .content-header-right .dropdown-toggle i,
html .content .content-wrapper .content-header-right .dropdown-toggle svg {
  margin-right: 0;
}

html .content .content-wrapper .content-header-right .btn-icon {
  padding: 0.8rem;
}

html .content .content-wrapper .content-header-right .btn-icon i,
html .content .content-wrapper .content-header-right .btn-icon svg {
  height: 1rem;
  width: 1rem;
}

html .navbar-floating.footer-static .app-content .content-area-wrapper,
html .navbar-floating.footer-static .app-content .kanban-wrapper {
  height: calc(
    100vh - calc(calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem)
  );
  height: calc(
    var(--vh, 1vh) * 100 -
      calc(calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-floating.footer-static .app-content .content-area-wrapper,
  html .navbar-floating.footer-static .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 1.3rem +
            0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 1.3rem +
            0rem
        )
    );
  }
}

html .navbar-floating.footer-hidden .app-content .content-area-wrapper,
html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 2) + 4.45rem + 0rem + 1.3rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 - calc(calc(2rem * 2) + 4.45rem + 0rem + 1.3rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-floating.footer-hidden .app-content .content-area-wrapper,
  html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 1.3rem +
            0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 1.3rem +
            0rem
        )
    );
  }
}

html .navbar-floating.footer-fixed .app-content .content-area-wrapper,
html .navbar-floating.footer-fixed .app-content .kanban-wrapper {
  height: calc(
    100vh - calc(calc(2rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem)
  );
  height: calc(
    var(--vh, 1vh) * 100 -
      calc(calc(2rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-floating.footer-fixed .app-content .content-area-wrapper,
  html .navbar-floating.footer-fixed .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 1.3rem +
            0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 1.3rem +
            0rem
        )
    );
  }
}

html .navbar-hidden .app-content {
  padding: 2rem 2rem 0 2rem !important;
}

html .navbar-hidden.footer-static .app-content .content-area-wrapper,
html .navbar-hidden.footer-static .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 1) + 0rem + 3.35rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 - calc(calc(2rem * 1) + 0rem + 3.35rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-hidden.footer-static .app-content .content-area-wrapper,
  html .navbar-hidden.footer-static .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 1) - calc(0.8rem * 1)) + 0rem + 3.35rem + 0rem + 0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 1) - calc(0.8rem * 1)) + 0rem + 3.35rem + 0rem + 0rem
        )
    );
  }
}

html .navbar-hidden.footer-hidden .app-content .content-area-wrapper,
html .navbar-hidden.footer-hidden .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 2) + 0rem + 0rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 - calc(calc(2rem * 2) + 0rem + 0rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-hidden.footer-hidden .app-content .content-area-wrapper,
  html .navbar-hidden.footer-hidden .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 0rem + 0rem + 0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 0rem + 0rem + 0rem
        )
    );
  }
}

html .navbar-hidden.footer-fixed .app-content .content-area-wrapper,
html .navbar-hidden.footer-fixed .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 2) + 0rem + 3.35rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 - calc(calc(2rem * 2) + 0rem + 3.35rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-hidden.footer-fixed .app-content .content-area-wrapper,
  html .navbar-hidden.footer-fixed .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 3.35rem + 0rem + 0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 3.35rem + 0rem + 0rem
        )
    );
  }
}

html .navbar-static .main-menu {
  top: 0;
}

html .navbar-static .content {
  min-height: calc(100% - calc(4.45rem + 3.35rem));
}

html .navbar-static .app-content {
  padding: 2rem 2rem 0 2rem;
}

html .navbar-static.footer-static .app-content .content-area-wrapper,
html .navbar-static.footer-static .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 -
      calc(calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-static.footer-static .app-content .content-area-wrapper,
  html .navbar-static.footer-static .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem +
            0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem +
            0rem
        )
    );
  }
}

html .navbar-static.footer-hidden .app-content .content-area-wrapper,
html .navbar-static.footer-hidden .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 - calc(calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-static.footer-hidden .app-content .content-area-wrapper,
  html .navbar-static.footer-hidden .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem
        )
    );
  }
}

html .navbar-static.footer-fixed .app-content .content-area-wrapper,
html .navbar-static.footer-fixed .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 -
      calc(calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-static.footer-fixed .app-content .content-area-wrapper,
  html .navbar-static.footer-fixed .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem +
            0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem +
            0rem
        )
    );
  }
}

html .navbar-sticky .app-content {
  padding: calc(2rem + 4.45rem) 2rem 0 2rem;
}

html .navbar-sticky.footer-static .app-content .content-area-wrapper,
html .navbar-sticky.footer-static .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 -
      calc(calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-sticky.footer-static .app-content .content-area-wrapper,
  html .navbar-sticky.footer-static .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem +
            0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem +
            0rem
        )
    );
  }
}

html .navbar-sticky.footer-hidden .app-content .content-area-wrapper,
html .navbar-sticky.footer-hidden .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 - calc(calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-sticky.footer-hidden .app-content .content-area-wrapper,
  html .navbar-sticky.footer-hidden .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem
        )
    );
  }
}

html .navbar-sticky.footer-fixed .app-content .content-area-wrapper,
html .navbar-sticky.footer-fixed .app-content .kanban-wrapper {
  height: calc(100vh - calc(calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem));
  height: calc(
    var(--vh, 1vh) * 100 -
      calc(calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem)
  );
}

@media (max-width: 575.98px) {
  html .navbar-sticky.footer-fixed .app-content .content-area-wrapper,
  html .navbar-sticky.footer-fixed .app-content .kanban-wrapper {
    height: calc(
      100vh -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem +
            0rem
        )
    );
    height: calc(
      var(--vh, 1vh) * 100 -
        calc(
          calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem +
            0rem
        )
    );
  }
}

html .navbar-static .nav.header-navbar .navbar-container,
html .navbar-sticky .nav.header-navbar .navbar-container {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

html
  .navbar-static
  .nav.header-navbar
  .navbar-container
  .search-input
  .search-list.show,
html
  .navbar-sticky
  .nav.header-navbar
  .navbar-container
  .search-input
  .search-list.show {
  width: 98%;
  left: 1%;
}

html .footer-fixed .content.app-content {
  padding-bottom: 3.35rem !important;
}

html [data-col="1-column"] .header-navbar {
  width: 100%;
  left: 0 !important;
}

html [data-col="1-column"] .header-navbar.floating-nav {
  width: calc(100vw - (100vw - 100%) - calc(2rem * 2)) !important;
}

html [data-col="1-column"] .content,
html [data-col="1-column"] .footer {
  margin-left: 0 !important;
}

html [data-col="1-column"].horizontal-layout .header-navbar {
  width: 100% !important;
}

html [data-col="1-column"].horizontal-layout .app-content {
  padding: calc(2rem + 4.45rem + 1.3rem) 2rem 0 2rem !important;
}

html [data-col="1-column"].horizontal-layout.footer-fixed .app-content {
  padding: calc(2rem + 4.45rem + 1.3rem) 2rem 3.35rem 2rem !important;
}

html .blank-page .content {
  margin-left: 0;
}

html .blank-page .content.app-content {
  overflow: overlay;
  overflow-x: hidden;
  padding: 0 !important;
}

html .blank-page .content.app-content .header-navbar-shadow {
  display: none;
}

html .blank-page .content-wrapper {
  padding: 0 !important;
}

html .blank-page .content-wrapper .flexbox-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

html .pace .pace-progress {
  background: #7367f0;
}

.app-content.center-layout {
  overflow: hidden;
}

/*
* Col 3 layout for detached and general type
*/
@media (min-width: 992px) {
  body .content-right {
    width: calc(100vw - (100vw - 100%) - 260px);
    float: right;
  }
  body .content-left {
    width: calc(100vw - (100vw - 100%) - 260px);
    float: left;
  }
  body .content-detached {
    width: 100%;
  }
  body .content-detached.content-right {
    float: right;
    margin-left: -260px;
  }
  body .content-detached.content-right .content-body {
    margin-left: calc(260px + 2rem);
  }
  body .content-detached.content-left {
    float: left;
    margin-right: -260px;
  }
  body .content-detached.content-left .content-body {
    margin-right: calc(260px + 2rem);
  }
  .sidebar-right.sidebar-sticky {
    float: right !important;
    margin-left: -260px;
    width: 260px !important;
    margin-top: 5.7rem;
  }
}

@media (max-width: 575.98px) {
  html body .app-content {
    padding: calc(2rem - 0.8rem + 4.45rem + 1.3rem) calc(2rem - 0.8rem) 0
      calc(2rem - 0.8rem) !important;
  }
  html body.navbar-static .app-content {
    padding: calc(2rem - 0.8rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
  html body.navbar-sticky .app-content {
    padding: calc(2rem - 0.8rem + 4.45rem) calc(2rem - 0.8rem) 0
      calc(2rem - 0.8rem) !important;
  }
  html body.navbar-hidden .app-content {
    padding: calc(2rem - 0.8rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
}

@media (max-width: 575.98px) {
  .navbar-static .app-content .header-navbar .navbar-container,
  .navbar-sticky .app-content .header-navbar .navbar-container {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html.full-screen {
    width: 100%;
  }
  html.full-screen .content.app-content {
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }
}

/*=========================================================================================
    File Name: sidebar.scss
    Description: content sidebar specific scss.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.sidebar {
  position: relative;
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .sidebar {
    vertical-align: top;
  }
}

.sidebar-fixed {
  position: fixed;
  height: 100%;
  overflow: scroll;
}

.sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(34, 41, 47, 0.5);
  z-index: 997;
  display: block;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sidenav-overlay.show {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.drag-target {
  height: 100%;
  width: 40px;
  position: fixed;
  top: 0;
  left: -10px;
  z-index: 1036;
}

.sidebar-detached .sidebar {
  width: 260px;
}

@media (min-width: 992px) {
  .sidebar-left {
    float: left;
  }
  .sidebar-right {
    float: right;
  }
}

@media (max-width: 991.98px) {
  .sidebar-detached .sidebar {
    width: 100%;
  }
}

footer.footer {
  padding: 0.8rem 2rem;
  min-height: 3.35rem;
}

footer.footer span i,
footer.footer span svg {
  height: 1.51rem;
  width: 1.51rem;
  font-size: 1.51rem;
  margin-left: 0.5rem;
  color: #ea5455;
}

footer.footer .scroll-top {
  padding: 0.81rem 0.83rem;
}

footer.footer-shadow {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

footer.footer-border {
  border-top: 1px solid #ebe9f1;
}

.footer-fixed footer {
  position: fixed !important;
  z-index: 1030;
  right: 0;
  left: 0;
  bottom: 0;
}

.footer-fixed footer.footer-light {
  background: #ffffff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.footer-fixed footer.footer-dark {
  background: #b8c2cc;
  color: #ffffff;
}

.scroll-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  display: none;
  z-index: 99;
}

/*=========================================================================================
    File Name: navigation.scss
    Description: Common mixin for menus, contain dark and light version scss.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.main-menu {
  z-index: 1031;
  position: absolute;
  display: table-cell;
  height: 100%;
  overflow: hidden;
}

.main-menu.menu-light {
  color: #6e6b7b;
  background: #ffffff;
}

/* changes made by kumarjeet poddar */

/* .main-menu.menu-light .navigation {
  background: #ffffff;
} */

.main-menu.menu-light .navigation .navigation-header {
  color: #a6a4b0;
  margin: calc(2rem + 0.286rem) 0 0.8rem 2.2rem;
  padding: 0;
  line-height: 1.5;
  letter-spacing: 0.01rem;
}

.main-menu.menu-light .navigation .navigation-header span {
  font-weight: 500;
}

.main-menu.menu-light .navigation li.has-sub > a {
  -webkit-transition: all 0.5s ease, background 0s, color 0s;
  transition: all 0.5s ease, background 0s, color 0s;
}

.main-menu.menu-light .navigation li a {
  color: #625f6e;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
}

.main-menu.menu-light .navigation li a span.menu-sub-title {
  color: #bdbbc4;
}

.main-menu.menu-light .navigation li a > * {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.main-menu.menu-light .navigation li.nav-item a:hover > * {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
}

.main-menu.menu-light .navigation li.nav-item ul.menu-content li a:hover > * {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
}

.main-menu.menu-light .navigation > li > a {
  margin: 0 15px;
}

.main-menu.menu-light .navigation > li > a .label {
  margin-top: 4px;
  margin-right: 5px;
}

.main-menu.menu-light .navigation > li > a i,
.main-menu.menu-light .navigation > li > a svg {
  position: relative;
  top: 1px;
}

.main-menu.menu-light .navigation > li > ul li:first-of-type {
  margin-top: 7px !important;
}

.main-menu.menu-light .navigation > li > ul li:not(.has-sub) {
  margin: 0 15px;
}

.main-menu.menu-light .navigation > li > ul li.has-sub > a {
  margin: 0 15px;
}

.main-menu.menu-light .navigation > li > ul li.has-sub > ul > li {
  margin: 0 15px;
}

.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a,
.main-menu.menu-light .navigation > li.sidebar-group-active > a {
  color: #565360;
  background: whitesmoke;
  border-radius: 6px;
}

.main-menu.menu-light .navigation > li:not(.open) > ul {
  display: none;
}

.main-menu.menu-light .navigation > li:not(.open) > ul .active {
  background: transparent;
  box-shadow: none;
}

.main-menu.menu-light .navigation > li.active {
  z-index: 1;
}

/* comment made by kumarjeet poddar */
/* .main-menu.menu-light .navigation > li.active > a {
  background: -webkit-linear-gradient(
    332deg,
    #7367f0,
    rgba(115, 103, 240, 0.7)
  );
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
} */

.main-menu.menu-light .navigation > li .active {
  background: #f0f0f0;
}

.main-menu.menu-light .navigation > li .active > a {
  color: #7367f0;
  margin-bottom: 0;
}

.main-menu.menu-light .navigation > li .active .hover > a,
.main-menu.menu-light .navigation > li .active :hover > a {
  background: whitesmoke;
}

.main-menu.menu-light .navigation > li ul {
  padding: 0;
  margin: 0;
}

.main-menu.menu-light .navigation > li ul li {
  color: #6e6b7b;
  background: transparent;
}

.main-menu.menu-light .navigation > li ul li > a {
  padding: 10px 15px 10px 20px;
}

.main-menu.menu-light .navigation > li ul li ul a {
  padding: 10px 15px 10px 53px;
}

.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul {
  display: none;
}

.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul .active {
  background: transparent;
  box-shadow: none;
}

.main-menu.menu-light .navigation > li ul .open > a,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > a {
  color: #6e6b7b;
  background: whitesmoke;
  border-radius: 6px;
}

.main-menu.menu-light .navigation > li ul .open > ul,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > ul {
  display: block;
}

.main-menu.menu-light .navigation > li ul .open > ul .open > ul,
.main-menu.menu-light
  .navigation
  > li
  ul
  .sidebar-group-active
  > ul
  .open
  > ul {
  display: block;
}

.main-menu.menu-light .navigation > li ul .hover > a,
.main-menu.menu-light .navigation > li ul:hover > a {
  color: #787486;
}

.main-menu.menu-light .navigation > li ul .active {
  background: -webkit-linear-gradient(
    332deg,
    #7367f0,
    rgba(115, 103, 240, 0.7)
  );
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  border-radius: 4px;
  z-index: 1;
}

.main-menu.menu-light .navigation > li ul .active > a {
  color: #ffffff;
}

.main-menu.menu-dark {
  color: #dcdcdc;
  background: #10163a;
}

.main-menu.menu-dark .navigation {
  background: #10163a;
}

.main-menu.menu-dark .navigation .navigation-header {
  color: white;
  margin: calc(2rem + 0.286rem) 0 0.8rem 2.2rem;
  padding: 0;
  line-height: 1.5;
  letter-spacing: 0.01rem;
}

.main-menu.menu-dark .navigation .navigation-header span {
  font-weight: 500;
}

.main-menu.menu-dark .navigation li.has-sub > a {
  -webkit-transition: all 0.5s ease, background 0s, color 0s;
  transition: all 0.5s ease, background 0s, color 0s;
}

.main-menu.menu-dark .navigation li a {
  color: #d0d0d0;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
}

.main-menu.menu-dark .navigation li a span.menu-sub-title {
  color: white;
}

.main-menu.menu-dark .navigation li a > * {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.main-menu.menu-dark .navigation li.nav-item a:hover > * {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
}

.main-menu.menu-dark .navigation li.nav-item ul.menu-content li a:hover > * {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
}

.main-menu.menu-dark .navigation > li > a {
  margin: 0 15px;
}

.main-menu.menu-dark .navigation > li > a .label {
  margin-top: 4px;
  margin-right: 5px;
}

.main-menu.menu-dark .navigation > li > a i,
.main-menu.menu-dark .navigation > li > a svg {
  position: relative;
  top: 1px;
}

.main-menu.menu-dark .navigation > li > ul li:first-of-type {
  margin-top: 7px !important;
}

.main-menu.menu-dark .navigation > li > ul li:not(.has-sub) {
  margin: 0 15px;
}

.main-menu.menu-dark .navigation > li > ul li.has-sub > a {
  margin: 0 15px;
}

.main-menu.menu-dark .navigation > li > ul li.has-sub > ul > li {
  margin: 0 15px;
}

.main-menu.menu-dark .navigation > li.open:not(.menu-item-closing) > a,
.main-menu.menu-dark .navigation > li.sidebar-group-active > a {
  color: #c3c3c3;
  background: #0c102a;
  border-radius: 6px;
}

.main-menu.menu-dark .navigation > li:not(.open) > ul {
  display: none;
}

.main-menu.menu-dark .navigation > li:not(.open) > ul .active {
  background: transparent;
  box-shadow: none;
}

.main-menu.menu-dark .navigation > li.active {
  z-index: 1;
}

.main-menu.menu-dark .navigation > li.active > a {
  background: -webkit-linear-gradient(
    332deg,
    #7367f0,
    rgba(115, 103, 240, 0.7)
  );
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
}

.main-menu.menu-dark .navigation > li .active {
  background: #090d22;
}

.main-menu.menu-dark .navigation > li .active > a {
  color: #7367f0;
  margin-bottom: 0;
}

.main-menu.menu-dark .navigation > li .active .hover > a,
.main-menu.menu-dark .navigation > li .active :hover > a {
  background: #0c102a;
}

.main-menu.menu-dark .navigation > li ul {
  padding: 0;
  margin: 0;
}

.main-menu.menu-dark .navigation > li ul li {
  color: #dcdcdc;
  background: transparent;
}

.main-menu.menu-dark .navigation > li ul li > a {
  padding: 10px 15px 10px 20px;
}

.main-menu.menu-dark .navigation > li ul li ul a {
  padding: 10px 15px 10px 53px;
}

.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul {
  display: none;
}

.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul .active {
  background: transparent;
  box-shadow: none;
}

.main-menu.menu-dark .navigation > li ul .open > a,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > a {
  color: #dcdcdc;
  background: #0c102a;
  border-radius: 6px;
}

.main-menu.menu-dark .navigation > li ul .open > ul,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul {
  display: block;
}

.main-menu.menu-dark .navigation > li ul .open > ul .open > ul,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul .open > ul {
  display: block;
}

.main-menu.menu-dark .navigation > li ul .hover > a,
.main-menu.menu-dark .navigation > li ul:hover > a {
  color: #e6e6e6;
}

.main-menu.menu-dark .navigation > li ul .active {
  background: -webkit-linear-gradient(
    332deg,
    #7367f0,
    rgba(115, 103, 240, 0.7)
  );
  background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7));
  box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
  border-radius: 4px;
  z-index: 1;
}

.main-menu.menu-dark .navigation > li ul .active > a {
  color: #ffffff;
}

.main-menu.menu-fixed {
  position: fixed;
}

.main-menu.menu-static {
  height: 100%;
  padding-bottom: calc(100% - 20rem);
}

.main-menu.menu-static .main-menu-content {
  height: unset !important;
}

.main-menu.menu-shadow {
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
}

.main-menu.menu-border {
  border-right: 1px solid #ebe9f1;
}

.main-menu .shadow-bottom {
  display: none;
  position: absolute;
  z-index: 2;
  height: 50px;
  width: 100%;
  pointer-events: none;
  margin-top: -0.7rem;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  background: -webkit-linear-gradient(
    #ffffff 41%,
    rgba(255, 255, 255, 0.11) 95%,
    rgba(255, 255, 255, 0)
  );
  background: linear-gradient(
    #ffffff 41%,
    rgba(255, 255, 255, 0.11) 95%,
    rgba(255, 255, 255, 0)
  );
}

.bordered-layout .main-menu .shadow-bottom {
  background: -webkit-linear-gradient(
    #f8f8f8 41%,
    rgba(255, 255, 255, 0.11) 95%,
    rgba(255, 255, 255, 0)
  );
  background: linear-gradient(
    #f8f8f8 41%,
    rgba(255, 255, 255, 0.11) 95%,
    rgba(255, 255, 255, 0)
  );
}

.main-menu.menu-native-scroll .main-menu-content {
  overflow-y: scroll !important;
}

.main-menu .navbar-header {
  height: 100%;
  width: 260px;
  height: 4.45rem;
  position: relative;
  padding: 0.35rem 1rem 0.3rem 1.64rem;
  -webkit-transition: 300ms ease all, background 0s;
  transition: 300ms ease all, background 0s;
}

.main-menu .navbar-header .navbar-brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1.35rem;
  font-size: inherit;
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 36px;
}

.main-menu .navbar-header .navbar-brand .brand-text {
  color: #7367f0;
  padding-left: 1rem;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: 0.01rem;
  font-size: 1.45rem;
  -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
    fadein;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.main-menu .navbar-header .modern-nav-toggle {
  -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
    fadein;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.main-menu .main-menu-content {
  height: calc(100% - 4.45rem) !important;
  position: relative;
}

.main-menu .main-menu-content .ps__rail-y {
  z-index: 3;
}

.main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-menu ul.navigation-main {
  overflow-x: hidden;
  padding-bottom: 0.75rem;
}

.main-menu ul.navigation-main > li:first-child {
  margin-top: 0.5rem;
}

.main-menu a {
  outline: none;
}

.main-menu a:hover,
.main-menu a:focus {
  text-decoration: none;
}

.navigation {
  font-size: 1.1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 400;
  overflow-y: hidden;
  padding-bottom: 20px;
}

.navigation .navigation-header {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 500;
  line-height: 1.2;
  padding: 12px 22px;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.navigation li {
  position: relative;
  white-space: nowrap;
}

.navigation li a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.navigation li a i,
.navigation li a svg {
  height: 11px;
  width: 11px;
  font-size: 11px;
  margin-right: 1.38rem;
}

.navigation li.menu-item-animating {
  -webkit-transition: height 0.25s ease-in-out;
  transition: height 0.25s ease-in-out;
}

.navigation li.disabled a {
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none;
}

@media (max-width: 767.98px) {
  .menu-hide .main-menu,
  .menu-open .main-menu {
    -webkit-transition: top 0.35s, height 0.35s, -webkit-transform 0.25s;
    transition: top 0.35s, height 0.35s, -webkit-transform 0.25s;
    transition: transform 0.25s, top 0.35s, height 0.35s;
    transition: transform 0.25s, top 0.35s, height 0.35s,
      -webkit-transform 0.25s;
  }
  .main-menu {
    -webkit-transform: translate3d(-240px, 0, 0);
    transform: translate3d(-240px, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
  }
  .menu-open .main-menu {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 768px) {
  .drag-target {
    z-index: 0;
  }
}

.avatar {
  white-space: nowrap;
  background-color: #c3c3c3;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  color: #ffffff;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
}

.avatar .avatar-content {
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  font-size: 0.857rem;
}

.avatar .avatar-content .avatar-icon {
  height: 1rem;
  width: 1rem;
}

.avatar [class*="avatar-status-"] {
  border-radius: 50%;
  width: 11px;
  height: 11px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid #ffffff;
}

.avatar [class*="avatar-status-"].avatar-status-lg,
.avatar [class*="avatar-status-"].avatar-status-xl {
  width: 17px;
  height: 17px;
  border-width: 2px;
}

.avatar .avatar-status-online {
  background-color: #28c76f;
}

.avatar .avatar-status-busy {
  background-color: #ea5455;
}

.avatar .avatar-status-away {
  background-color: #ff9f43;
}

.avatar .avatar-status-offline {
  background-color: #82868b;
}

.avatar.avatar-tag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 0.357rem;
}

.avatar img {
  border-radius: 50%;
}

.avatar.avatar-xl {
  font-size: 1.5rem;
}

.avatar.avatar-xl img {
  width: 70px;
  height: 70px;
}

.avatar.avatar-xl .avatar-content {
  height: 70px;
  width: 70px;
  font-size: 2.57rem;
}

.avatar.avatar-xl .avatar-content .avatar-icon,
.avatar.avatar-xl .avatar-content i,
.avatar.avatar-xl .avatar-content svg {
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
}

.avatar.avatar-xl [class*="avatar-status-"] {
  right: 3px;
  bottom: 0;
}

.avatar.avatar-lg {
  font-size: 1.2rem;
}

.avatar.avatar-lg img {
  width: 50px;
  height: 50px;
}

.avatar.avatar-lg .avatar-content {
  width: 50px;
  height: 50px;
  font-size: 1.714rem;
}

.avatar.avatar-lg .avatar-content .avatar-icon,
.avatar.avatar-lg .avatar-content i,
.avatar.avatar-lg .avatar-content svg {
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
}

.avatar.avatar-lg [class*="avatar-status-"] {
  right: 3px;
  bottom: 1px;
}

.avatar.avatar-sm .avatar-content {
  width: 24px;
  height: 24px;
}

.avatar.avatar-sm .avatar-content .avatar-icon,
.avatar.avatar-sm .avatar-content i,
.avatar.avatar-sm .avatar-content svg {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
}

.avatar.avatar-sm img {
  width: 24px;
  height: 24px;
}

.avatar.avatar-sm [class*="avatar-status-"] {
  right: -3px;
  bottom: -2px;
}

.avatar-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.avatar-group .avatar {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.avatar-group .avatar img,
.avatar-group .avatar .avatar-content {
  box-shadow: 0 0 0 2px #ffffff, inset 0 0 0 1px rgba(34, 41, 47, 0.07);
}

.avatar-group .avatar .avatar-content {
  background-color: #b7b9bc !important;
}

.avatar-group .avatar:hover {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.avatar-group .avatar.pull-up:hover {
  -webkit-transform: translateY(-4px) scale(1.07);
  -ms-transform: translateY(-4px) scale(1.07);
  transform: translateY(-4px) scale(1.07);
}

.avatar-group .avatar:not(:first-child) {
  margin-left: -0.55rem;
}

.avatar-group .avatar-sm:not(:first-child) {
  margin-left: -0.4rem;
}

.avatar-group .avatar-lg:not(:first-child) {
  margin-left: -1rem;
}

.avatar-group .avatar-xl:not(:first-child) {
  margin-left: -1.5rem;
}

/*=========================================================================================
	File Name: search.scss
	Description: Search  functionality.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.navbar-container a.nav-link-search {
  float: left;
}

.navbar-container .search-input {
  width: 0;
  float: left;
  display: none;
}

.navbar-container .search-input input {
  width: 0;
  border: none;
  background: none;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  line-height: 16px;
  padding: 1.6rem 3.6rem;
}

.navbar-container .search-input.open {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  display: block;
  background: #ffffff;
  border-radius: 0.5rem;
}

.navbar-container .search-input.open .search-input-close,
.navbar-container .search-input.open .search-input-icon {
  display: block;
}

.navbar-container .search-input.open input {
  width: 100%;
  outline: none;
  background: none;
  height: auto;
  box-shadow: none;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-container .search-input .search-list {
  max-height: 450px;
  height: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  width: 100%;
  margin-top: 0.5rem;
  padding-left: 0;
  border-radius: 0.357rem;
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 875px) and (orientation: landscape) {
  .navbar-container .search-input .search-list {
    max-height: 250px;
  }
}

.navbar-container .search-input .search-list.search-list-bookmark {
  height: auto !important;
}

.navbar-container .search-input .search-list.search-list-bookmark i,
.navbar-container .search-input .search-list.search-list-bookmark svg {
  font-size: 1.2rem;
  height: 1.2rem;
  width: 1.2rem;
}

.navbar-container .search-input .search-list.show {
  display: block;
  min-height: 3rem;
}

.navbar-container .search-input .search-list li a {
  padding: 0.9rem 1rem;
  color: #6e6b7b;
}

.navbar-container .search-input .search-list li a i,
.navbar-container
  .search-input
  .search-list
  li
  a
  svg[class*="feather feather-"] {
  height: 1.25rem;
  width: 1.25rem;
  font-size: 1.25rem;
}

.navbar-container .search-input .search-list li:first-child {
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}

.navbar-container .search-input .search-list li:last-child {
  border-bottom-left-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
}

.navbar-container .search-input .search-list li.auto-suggestion {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.navbar-container .search-input .search-list li.auto-suggestion:hover,
.navbar-container .search-input .search-list li.auto-suggestion.current_item {
  background-color: #f8f8f8;
}

.navbar-container .search-input .search-input-icon {
  z-index: 2;
  display: none;
  position: absolute;
  left: 1.5rem;
  top: 32%;
  cursor: pointer;
}

.navbar-container .search-input .search-input-icon i,
.navbar-container .search-input .search-input-icon svg {
  height: 1.25rem;
  width: 1.25rem;
  font-size: 1.25rem;
}

.navbar-container .search-input .search-input-close {
  z-index: 1;
  display: none;
  position: absolute;
  right: 2rem;
  top: 32%;
  cursor: pointer;
}

.navbar-container .search-input .search-input-close i,
.navbar-container .search-input .search-input-close svg {
  height: 1.25rem;
  width: 1.25rem;
  font-size: 1.25rem;
}

.navbar-container .bookmark-input {
  position: absolute;
  top: 102%;
  width: 25%;
  display: none;
  background: #ffffff;
  border-radius: 0.357rem;
  box-shadow: 0 15px 30px 0 rgba(34, 41, 47, 0.11),
    0 5px 15px 0 rgba(34, 41, 47, 0.08);
}

.navbar-container .bookmark-input.show {
  display: block;
}

.navbar-container .bookmark-input .bookmark-input-icon {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 24px;
}

.navbar-container .bookmark-input input {
  padding: 0.7rem 0.7rem 0.7rem 2.5rem;
  margin: 12px;
  width: calc(100% - 24px);
  background: #ffffff;
  border: 1px solid rgba(34, 41, 47, 0.2);
}

.navbar-container .bookmark-input input:focus {
  border: 1px solid #7367f0;
}

.navbar-container .bookmark-input .search-list {
  position: relative;
}

.navbar-container .bookmark-icon {
  cursor: pointer;
}

.navbar-container .dropdown.bookmark-dropdown {
  padding: 1.4rem 0.5rem 1.35rem;
}

.navbar-container .dropdown.bookmark-dropdown .dropdown-toggle:after {
  display: none;
}

.navbar-container .search-data-title {
  line-height: 1.25rem;
}

.navbar-dark .search-input .input {
  color: #f5f7fa;
  border-radius: 0.5rem;
}

.navbar-dark .search-input.open .input {
  color: #f5f7fa;
  border: 1px solid #f5f7fa;
}

.navbar-static .navbar-container .search-input.open {
  border-radius: 0;
}

.btn-social,
.btn-social-icon {
  position: relative;
  padding-left: 4.95rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-social > :first-child,
.btn-social-icon > :first-child {
  position: absolute;
  left: 0;
  top: 2px;
  bottom: 0;
  width: 2.95rem;
  line-height: 2.7rem;
  font-size: 1rem;
  text-align: center;
  border-right: 1px solid rgba(34, 41, 47, 0.2);
}

.btn-social-icon {
  height: 2.95rem;
  width: 2.95rem;
  padding: 0;
}

.btn-social-icon > :first-child {
  border: none;
  text-align: center;
  width: 100% !important;
}

.btn-adn {
  background-color: #d87a68;
  color: #ffffff;
  background-color: #d87a68;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-adn:hover {
  color: #ffffff;
  background-color: #d87a68;
  border-color: #d87a68;
}

.btn-adn:focus,
.btn-adn.focus {
  color: #ffffff;
  background-color: #d87a68;
  border-color: #d87a68;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-adn.disabled,
.btn-adn:disabled {
  color: #ffffff;
  background-color: #d87a68;
  border-color: #ffffff;
}

.btn-adn:not(:disabled):not(.disabled):active,
.btn-adn:not(:disabled):not(.disabled).active,
.show > .btn-adn.dropdown-toggle {
  color: #ffffff;
  background-color: #ce563f;
  border-color: #dfdfdf;
}

.btn-adn:not(:disabled):not(.disabled):active:focus,
.btn-adn:not(:disabled):not(.disabled).active:focus,
.show > .btn-adn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-bitbucket {
  background-color: #205081;
  color: #ffffff;
  background-color: #205081;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-bitbucket:hover {
  color: #ffffff;
  background-color: #205081;
  border-color: #205081;
}

.btn-bitbucket:focus,
.btn-bitbucket.focus {
  color: #ffffff;
  background-color: #205081;
  border-color: #205081;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-bitbucket.disabled,
.btn-bitbucket:disabled {
  color: #ffffff;
  background-color: #205081;
  border-color: #ffffff;
}

.btn-bitbucket:not(:disabled):not(.disabled):active,
.btn-bitbucket:not(:disabled):not(.disabled).active,
.show > .btn-bitbucket.dropdown-toggle {
  color: #ffffff;
  background-color: #163758;
  border-color: #dfdfdf;
}

.btn-bitbucket:not(:disabled):not(.disabled):active:focus,
.btn-bitbucket:not(:disabled):not(.disabled).active:focus,
.show > .btn-bitbucket.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-dropbox {
  background-color: #1087dd;
  color: #ffffff;
  background-color: #1087dd;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-dropbox:hover {
  color: #ffffff;
  background-color: #1087dd;
  border-color: #1087dd;
}

.btn-dropbox:focus,
.btn-dropbox.focus {
  color: #ffffff;
  background-color: #1087dd;
  border-color: #1087dd;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-dropbox.disabled,
.btn-dropbox:disabled {
  color: #ffffff;
  background-color: #1087dd;
  border-color: #ffffff;
}

.btn-dropbox:not(:disabled):not(.disabled):active,
.btn-dropbox:not(:disabled):not(.disabled).active,
.show > .btn-dropbox.dropdown-toggle {
  color: #ffffff;
  background-color: #0d6aad;
  border-color: #dfdfdf;
}

.btn-dropbox:not(:disabled):not(.disabled):active:focus,
.btn-dropbox:not(:disabled):not(.disabled).active:focus,
.show > .btn-dropbox.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook {
  background-color: #3b5998;
  color: #ffffff;
  background-color: #3b5998;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-facebook:hover {
  color: #ffffff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:focus,
.btn-facebook.focus {
  color: #ffffff;
  background-color: #3b5998;
  border-color: #3b5998;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #ffffff;
  background-color: #3b5998;
  border-color: #ffffff;
}

.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #ffffff;
  background-color: #2d4373;
  border-color: #dfdfdf;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-flickr {
  background-color: #ff0084;
  color: #ffffff;
  background-color: #ff0084;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-flickr:hover {
  color: #ffffff;
  background-color: #ff0084;
  border-color: #ff0084;
}

.btn-flickr:focus,
.btn-flickr.focus {
  color: #ffffff;
  background-color: #ff0084;
  border-color: #ff0084;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-flickr.disabled,
.btn-flickr:disabled {
  color: #ffffff;
  background-color: #ff0084;
  border-color: #ffffff;
}

.btn-flickr:not(:disabled):not(.disabled):active,
.btn-flickr:not(:disabled):not(.disabled).active,
.show > .btn-flickr.dropdown-toggle {
  color: #ffffff;
  background-color: #cc006a;
  border-color: #dfdfdf;
}

.btn-flickr:not(:disabled):not(.disabled):active:focus,
.btn-flickr:not(:disabled):not(.disabled).active:focus,
.show > .btn-flickr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-foursquare {
  background-color: #f94877;
  color: #ffffff;
  background-color: #f94877;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-foursquare:hover {
  color: #ffffff;
  background-color: #f94877;
  border-color: #f94877;
}

.btn-foursquare:focus,
.btn-foursquare.focus {
  color: #ffffff;
  background-color: #f94877;
  border-color: #f94877;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-foursquare.disabled,
.btn-foursquare:disabled {
  color: #ffffff;
  background-color: #f94877;
  border-color: #ffffff;
}

.btn-foursquare:not(:disabled):not(.disabled):active,
.btn-foursquare:not(:disabled):not(.disabled).active,
.show > .btn-foursquare.dropdown-toggle {
  color: #ffffff;
  background-color: #f71752;
  border-color: #dfdfdf;
}

.btn-foursquare:not(:disabled):not(.disabled):active:focus,
.btn-foursquare:not(:disabled):not(.disabled).active:focus,
.show > .btn-foursquare.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-github {
  background-color: #444444;
  color: #ffffff;
  background-color: #444444;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-github:hover {
  color: #ffffff;
  background-color: #444444;
  border-color: #444444;
}

.btn-github:focus,
.btn-github.focus {
  color: #ffffff;
  background-color: #444444;
  border-color: #444444;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-github.disabled,
.btn-github:disabled {
  color: #ffffff;
  background-color: #444444;
  border-color: #ffffff;
}

.btn-github:not(:disabled):not(.disabled):active,
.btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: #ffffff;
  background-color: #2b2b2b;
  border-color: #dfdfdf;
}

.btn-github:not(:disabled):not(.disabled):active:focus,
.btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-google {
  background-color: #dd4b39;
  color: #ffffff;
  background-color: #dd4b39;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-google:hover {
  color: #ffffff;
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.btn-google:focus,
.btn-google.focus {
  color: #ffffff;
  background-color: #dd4b39;
  border-color: #dd4b39;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-google.disabled,
.btn-google:disabled {
  color: #ffffff;
  background-color: #dd4b39;
  border-color: #ffffff;
}

.btn-google:not(:disabled):not(.disabled):active,
.btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
  color: #ffffff;
  background-color: #c23321;
  border-color: #dfdfdf;
}

.btn-google:not(:disabled):not(.disabled):active:focus,
.btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-instagram {
  background-color: #3f729b;
  color: #ffffff;
  background-color: #3f729b;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-instagram:hover {
  color: #ffffff;
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:focus,
.btn-instagram.focus {
  color: #ffffff;
  background-color: #3f729b;
  border-color: #3f729b;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-instagram.disabled,
.btn-instagram:disabled {
  color: #ffffff;
  background-color: #3f729b;
  border-color: #ffffff;
}

.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  color: #ffffff;
  background-color: #305777;
  border-color: #dfdfdf;
}

.btn-instagram:not(:disabled):not(.disabled):active:focus,
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-linkedin {
  background-color: #007bb6;
  color: #ffffff;
  background-color: #007bb6;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-linkedin:hover {
  color: #ffffff;
  background-color: #007bb6;
  border-color: #007bb6;
}

.btn-linkedin:focus,
.btn-linkedin.focus {
  color: #ffffff;
  background-color: #007bb6;
  border-color: #007bb6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-linkedin.disabled,
.btn-linkedin:disabled {
  color: #ffffff;
  background-color: #007bb6;
  border-color: #ffffff;
}

.btn-linkedin:not(:disabled):not(.disabled):active,
.btn-linkedin:not(:disabled):not(.disabled).active,
.show > .btn-linkedin.dropdown-toggle {
  color: #ffffff;
  background-color: #005983;
  border-color: #dfdfdf;
}

.btn-linkedin:not(:disabled):not(.disabled):active:focus,
.btn-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-microsoft {
  background-color: #2672ec;
  color: #ffffff;
  background-color: #2672ec;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-microsoft:hover {
  color: #ffffff;
  background-color: #2672ec;
  border-color: #2672ec;
}

.btn-microsoft:focus,
.btn-microsoft.focus {
  color: #ffffff;
  background-color: #2672ec;
  border-color: #2672ec;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-microsoft.disabled,
.btn-microsoft:disabled {
  color: #ffffff;
  background-color: #2672ec;
  border-color: #ffffff;
}

.btn-microsoft:not(:disabled):not(.disabled):active,
.btn-microsoft:not(:disabled):not(.disabled).active,
.show > .btn-microsoft.dropdown-toggle {
  color: #ffffff;
  background-color: #125acd;
  border-color: #dfdfdf;
}

.btn-microsoft:not(:disabled):not(.disabled):active:focus,
.btn-microsoft:not(:disabled):not(.disabled).active:focus,
.show > .btn-microsoft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-odnoklassniki {
  background-color: #f4731c;
  color: #ffffff;
  background-color: #f4731c;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-odnoklassniki:hover {
  color: #ffffff;
  background-color: #f4731c;
  border-color: #f4731c;
}

.btn-odnoklassniki:focus,
.btn-odnoklassniki.focus {
  color: #ffffff;
  background-color: #f4731c;
  border-color: #f4731c;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-odnoklassniki.disabled,
.btn-odnoklassniki:disabled {
  color: #ffffff;
  background-color: #f4731c;
  border-color: #ffffff;
}

.btn-odnoklassniki:not(:disabled):not(.disabled):active,
.btn-odnoklassniki:not(:disabled):not(.disabled).active,
.show > .btn-odnoklassniki.dropdown-toggle {
  color: #ffffff;
  background-color: #d35b0a;
  border-color: #dfdfdf;
}

.btn-odnoklassniki:not(:disabled):not(.disabled):active:focus,
.btn-odnoklassniki:not(:disabled):not(.disabled).active:focus,
.show > .btn-odnoklassniki.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-openid {
  background-color: #f7931e;
  color: #2a2e30;
  background-color: #f7931e;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-openid:hover {
  color: #2a2e30;
  background-color: #f7931e;
  border-color: #f7931e;
}

.btn-openid:focus,
.btn-openid.focus {
  color: #2a2e30;
  background-color: #f7931e;
  border-color: #f7931e;
  box-shadow: 0 0 0 0.2rem rgba(223, 224, 224, 0.5);
}

.btn-openid.disabled,
.btn-openid:disabled {
  color: #2a2e30;
  background-color: #f7931e;
  border-color: #ffffff;
}

.btn-openid:not(:disabled):not(.disabled):active,
.btn-openid:not(:disabled):not(.disabled).active,
.show > .btn-openid.dropdown-toggle {
  color: #ffffff;
  background-color: #da7908;
  border-color: #dfdfdf;
}

.btn-openid:not(:disabled):not(.disabled):active:focus,
.btn-openid:not(:disabled):not(.disabled).active:focus,
.show > .btn-openid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 224, 224, 0.5);
}

.btn-pinterest {
  background-color: #cb2027;
  color: #ffffff;
  background-color: #cb2027;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-pinterest:hover {
  color: #ffffff;
  background-color: #cb2027;
  border-color: #cb2027;
}

.btn-pinterest:focus,
.btn-pinterest.focus {
  color: #ffffff;
  background-color: #cb2027;
  border-color: #cb2027;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-pinterest.disabled,
.btn-pinterest:disabled {
  color: #ffffff;
  background-color: #cb2027;
  border-color: #ffffff;
}

.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle {
  color: #ffffff;
  background-color: #9f191f;
  border-color: #dfdfdf;
}

.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-reddit {
  background-color: #eff7ff;
  color: #2a2e30;
  background-color: #eff7ff;
  border-color: #000000;
  color: #ffffff !important;
  border: none;
}

.btn-reddit:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.btn-reddit:focus,
.btn-reddit.focus {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
  box-shadow: 0 0 0 0.2rem rgba(6, 7, 7, 0.5);
}

.btn-reddit.disabled,
.btn-reddit:disabled {
  color: #2a2e30;
  background-color: #eff7ff;
  border-color: #000000;
}

.btn-reddit:not(:disabled):not(.disabled):active,
.btn-reddit:not(:disabled):not(.disabled).active,
.show > .btn-reddit.dropdown-toggle {
  color: #2a2e30;
  background-color: #bcdeff;
  border-color: black;
}

.btn-reddit:not(:disabled):not(.disabled):active:focus,
.btn-reddit:not(:disabled):not(.disabled).active:focus,
.show > .btn-reddit.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(6, 7, 7, 0.5);
}

.btn-soundcloud {
  background-color: #ff5500;
  color: #ffffff;
  background-color: #ff5500;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-soundcloud:hover {
  color: #ffffff;
  background-color: #ff5500;
  border-color: #ff5500;
}

.btn-soundcloud:focus,
.btn-soundcloud.focus {
  color: #ffffff;
  background-color: #ff5500;
  border-color: #ff5500;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-soundcloud.disabled,
.btn-soundcloud:disabled {
  color: #ffffff;
  background-color: #ff5500;
  border-color: #ffffff;
}

.btn-soundcloud:not(:disabled):not(.disabled):active,
.btn-soundcloud:not(:disabled):not(.disabled).active,
.show > .btn-soundcloud.dropdown-toggle {
  color: #ffffff;
  background-color: #cc4400;
  border-color: #dfdfdf;
}

.btn-soundcloud:not(:disabled):not(.disabled):active:focus,
.btn-soundcloud:not(:disabled):not(.disabled).active:focus,
.show > .btn-soundcloud.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-tumblr {
  background-color: #2c4762;
  color: #ffffff;
  background-color: #2c4762;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-tumblr:hover {
  color: #ffffff;
  background-color: #2c4762;
  border-color: #2c4762;
}

.btn-tumblr:focus,
.btn-tumblr.focus {
  color: #ffffff;
  background-color: #2c4762;
  border-color: #2c4762;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-tumblr.disabled,
.btn-tumblr:disabled {
  color: #ffffff;
  background-color: #2c4762;
  border-color: #ffffff;
}

.btn-tumblr:not(:disabled):not(.disabled):active,
.btn-tumblr:not(:disabled):not(.disabled).active,
.show > .btn-tumblr.dropdown-toggle {
  color: #ffffff;
  background-color: #1c2e3f;
  border-color: #dfdfdf;
}

.btn-tumblr:not(:disabled):not(.disabled):active:focus,
.btn-tumblr:not(:disabled):not(.disabled).active:focus,
.show > .btn-tumblr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-twitter {
  background-color: #55acee;
  color: #2a2e30;
  background-color: #55acee;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-twitter:hover {
  color: #2a2e30;
  background-color: #55acee;
  border-color: #55acee;
}

.btn-twitter:focus,
.btn-twitter.focus {
  color: #2a2e30;
  background-color: #55acee;
  border-color: #55acee;
  box-shadow: 0 0 0 0.2rem rgba(223, 224, 224, 0.5);
}

.btn-twitter.disabled,
.btn-twitter:disabled {
  color: #2a2e30;
  background-color: #55acee;
  border-color: #ffffff;
}

.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #ffffff;
  background-color: #2795e9;
  border-color: #dfdfdf;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 224, 224, 0.5);
}

.btn-vimeo {
  background-color: #1ab7ea;
  color: #ffffff;
  background-color: #1ab7ea;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-vimeo:hover {
  color: #ffffff;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}

.btn-vimeo:focus,
.btn-vimeo.focus {
  color: #ffffff;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-vimeo.disabled,
.btn-vimeo:disabled {
  color: #ffffff;
  background-color: #1ab7ea;
  border-color: #ffffff;
}

.btn-vimeo:not(:disabled):not(.disabled):active,
.btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle {
  color: #ffffff;
  background-color: #1295bf;
  border-color: #dfdfdf;
}

.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-vk {
  background-color: #587ea3;
  color: #ffffff;
  background-color: #587ea3;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-vk:hover {
  color: #ffffff;
  background-color: #587ea3;
  border-color: #587ea3;
}

.btn-vk:focus,
.btn-vk.focus {
  color: #ffffff;
  background-color: #587ea3;
  border-color: #587ea3;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-vk.disabled,
.btn-vk:disabled {
  color: #ffffff;
  background-color: #587ea3;
  border-color: #ffffff;
}

.btn-vk:not(:disabled):not(.disabled):active,
.btn-vk:not(:disabled):not(.disabled).active,
.show > .btn-vk.dropdown-toggle {
  color: #ffffff;
  background-color: #466482;
  border-color: #dfdfdf;
}

.btn-vk:not(:disabled):not(.disabled):active:focus,
.btn-vk:not(:disabled):not(.disabled).active:focus,
.show > .btn-vk.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-yahoo {
  background-color: #720e9e;
  color: #ffffff;
  background-color: #720e9e;
  border-color: #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-yahoo:hover {
  color: #ffffff;
  background-color: #720e9e;
  border-color: #720e9e;
}

.btn-yahoo:focus,
.btn-yahoo.focus {
  color: #ffffff;
  background-color: #720e9e;
  border-color: #720e9e;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-yahoo.disabled,
.btn-yahoo:disabled {
  color: #ffffff;
  background-color: #720e9e;
  border-color: #ffffff;
}

.btn-yahoo:not(:disabled):not(.disabled):active,
.btn-yahoo:not(:disabled):not(.disabled).active,
.show > .btn-yahoo.dropdown-toggle {
  color: #ffffff;
  background-color: #500a6f;
  border-color: #dfdfdf;
}

.btn-yahoo:not(:disabled):not(.disabled):active:focus,
.btn-yahoo:not(:disabled):not(.disabled).active:focus,
.show > .btn-yahoo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-adn {
  border: 1px solid #d87a68 !important;
  color: #d87a68;
}

.btn-outline-bitbucket {
  border: 1px solid #205081 !important;
  color: #205081;
}

.btn-outline-dropbox {
  border: 1px solid #1087dd !important;
  color: #1087dd;
}

.btn-outline-facebook {
  border: 1px solid #3b5998 !important;
  color: #3b5998;
}

.btn-outline-flickr {
  border: 1px solid #ff0084 !important;
  color: #ff0084;
}

.btn-outline-foursquare {
  border: 1px solid #f94877 !important;
  color: #f94877;
}

.btn-outline-github {
  border: 1px solid #444444 !important;
  color: #444444;
}

.btn-outline-google {
  border: 1px solid #dd4b39 !important;
  color: #dd4b39;
}

.btn-outline-instagram {
  border: 1px solid #3f729b !important;
  color: #3f729b;
}

.btn-outline-linkedin {
  border: 1px solid #007bb6 !important;
  color: #007bb6;
}

.btn-outline-microsoft {
  border: 1px solid #2672ec !important;
  color: #2672ec;
}

.btn-outline-odnoklassniki {
  border: 1px solid #f4731c !important;
  color: #f4731c;
}

.btn-outline-openid {
  border: 1px solid #f7931e !important;
  color: #f7931e;
}

.btn-outline-pinterest {
  border: 1px solid #cb2027 !important;
  color: #cb2027;
}

.btn-outline-reddit {
  border: 1px solid #ff4500 !important;
  color: #ff4500;
}

.btn-outline-soundcloud {
  border: 1px solid #ff5500 !important;
  color: #ff5500;
}

.btn-outline-tumblr {
  border: 1px solid #2c4762 !important;
  color: #2c4762;
}

.btn-outline-twitter {
  border: 1px solid #55acee !important;
  color: #55acee;
}

.btn-outline-vimeo {
  border: 1px solid #1ab7ea !important;
  color: #1ab7ea;
}

.btn-outline-vk {
  border: 1px solid #587ea3 !important;
  color: #587ea3;
}

.btn-outline-yahoo {
  border: 1px solid #720e9e !important;
  color: #720e9e;
}

.btn-outline-adn:hover {
  color: #ad412d;
  border: 1px solid #ad412d !important;
}

.btn-outline-bitbucket:hover {
  color: #0c1d2f;
  border: 1px solid #0c1d2f !important;
}

.btn-outline-dropbox:hover {
  color: #094d7e;
  border: 1px solid #094d7e !important;
}

.btn-outline-facebook:hover {
  color: #1e2e4f;
  border: 1px solid #1e2e4f !important;
}

.btn-outline-flickr:hover {
  color: #99004f;
  border: 1px solid #99004f !important;
}

.btn-outline-foursquare:hover {
  color: #d4073d;
  border: 1px solid #d4073d !important;
}

.btn-outline-github:hover {
  color: #111111;
  border: 1px solid #111111 !important;
}

.btn-outline-google:hover {
  color: #96271a;
  border: 1px solid #96271a !important;
}

.btn-outline-instagram:hover {
  color: #223d52;
  border: 1px solid #223d52 !important;
}

.btn-outline-linkedin:hover {
  color: #003650;
  border: 1px solid #003650 !important;
}

.btn-outline-microsoft:hover {
  color: #0e459e;
  border: 1px solid #0e459e !important;
}

.btn-outline-odnoklassniki:hover {
  color: #a24608;
  border: 1px solid #a24608 !important;
}

.btn-outline-openid:hover {
  color: #a95e06;
  border: 1px solid #a95e06 !important;
}

.btn-outline-pinterest:hover {
  color: #731216;
  border: 1px solid #731216 !important;
}

.btn-outline-reddit:hover {
  color: #992900;
  border: 1px solid #992900 !important;
}

.btn-outline-soundcloud:hover {
  color: #993300;
  border: 1px solid #993300 !important;
}

.btn-outline-tumblr:hover {
  color: #0c141c;
  border: 1px solid #0c141c !important;
}

.btn-outline-twitter:hover {
  color: #147bc9;
  border: 1px solid #147bc9 !important;
}

.btn-outline-vimeo:hover {
  color: #0d7091;
  border: 1px solid #0d7091 !important;
}

.btn-outline-vk:hover {
  color: #344b61;
  border: 1px solid #344b61 !important;
}

.btn-outline-yahoo:hover {
  color: #2e0640;
  border: 1px solid #2e0640 !important;
}

.bg-adn {
  background-color: #d87a68;
}

.bg-bitbucket {
  background-color: #205081;
}

.bg-dropbox {
  background-color: #1087dd;
}

.bg-facebook {
  background-color: #3b5998;
}

.bg-flickr {
  background-color: #ff0084;
}

.bg-foursquare {
  background-color: #f94877;
}

.bg-github {
  background-color: #444444;
}

.bg-google {
  background-color: #dd4b39;
}

.bg-instagram {
  background-color: #3f729b;
}

.bg-linkedin {
  background-color: #007bb6;
}

.bg-microsoft {
  background-color: #2672ec;
}

.bg-odnoklassniki {
  background-color: #f4731c;
}

.bg-openid {
  background-color: #f7931e;
}

.bg-pinterest {
  background-color: #cb2027;
}

.bg-reddit {
  background-color: #ff4500;
}

.bg-soundcloud {
  background-color: #ff5500;
}

.bg-tumblr {
  background-color: #2c4762;
}

.bg-twitter {
  background-color: #55acee;
}

.bg-vimeo {
  background-color: #1ab7ea;
}

.bg-vk {
  background-color: #587ea3;
}

.bg-yahoo {
  background-color: #720e9e;
}

/*=========================================================================================
    File Name: demo.scss
    Description: CSS used for demo purpose only. Remove this css from your project.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.demo-inline-spacing {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.demo-inline-spacing > * {
  margin-right: 1.5rem;
  margin-top: 1.5rem;
}

.demo-vertical-spacing > * {
  margin: 0;
  margin-bottom: 1.5rem;
}

.demo-vertical-spacing > *:last-child {
  margin: 0;
}

.demo-spacing-0 > *:last-child {
  margin: 0;
}

.icon-wrapper .feather {
  height: 24px;
  width: 24px;
}

.scroll-example {
  padding: 0.5rem;
  position: relative;
  border: 2px solid #dfdfdf;
  overflow: auto;
}

.scroll-example .horz-scroll-content {
  width: 1200px;
}

.browser {
  background: #e0e0e0;
  border: 4px solid #e0e0e0;
  width: 100%;
  height: 12rem;
  padding-top: 20px;
  margin: 0 0 10px;
  box-sizing: border-box;
}

.browser iframe {
  border: 0;
  background: #ffffff;
  height: 100%;
  width: 100%;
}

.loader-wrapper {
  height: 8em;
}

.maintenance-icon {
  font-size: 4rem;
}

.animationIcon {
  right: 30px;
  bottom: 10px;
}

#component-breadcrumbs .breadcrumb:not(last-child) {
  margin-bottom: 0.5rem;
}

#breadcrumb-alignment .breadcrumb-wrapper {
  box-shadow: 1px 1px 10px rgba(34, 41, 47, 0.1);
  padding: 1px;
  border-radius: 0.357rem;
}

#breadcrumb-alignment .breadcrumb-wrapper .breadcrumb {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.dark-layout #breadcrumb-alignment .breadcrumb-wrapper {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24);
}

.buy-now {
  position: fixed;
  bottom: 5%;
  right: 79px;
  z-index: 1031;
}

.buy-now .btn {
  box-shadow: 0 1px 20px 1px #ea5455 !important;
}

.buy-now .btn:hover {
  box-shadow: none !important;
}

/*=========================================================================================
	File Name: customizer.scss
	Description: CSS used for demo purpose only. Remove this css from your project.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.customizer {
  width: 400px;
  right: -400px;
  padding: 0;
  background-color: #ffffff;
  z-index: 1051;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  -webkit-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-left: 1px solid rgba(34, 41, 47, 0.05);
}

.customizer.open {
  box-shadow: 0 15px 30px 0 rgba(34, 41, 47, 0.11),
    0 5px 15px 0 rgba(34, 41, 47, 0.08);
  right: 0;
}

.customizer .customizer-content {
  position: relative;
  height: 100%;
}

.customizer .customizer-close {
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
  padding: 7px;
  width: auto;
  z-index: 10;
  color: #5e5873;
}

.customizer .customizer-close i,
.customizer .customizer-close svg {
  font-size: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

.customizer .customizer-toggle {
  background: #7367f0;
  color: #ffffff !important;
  display: block;
  box-shadow: -3px 0 8px rgba(34, 41, 47, 0.1);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: absolute;
  top: 50%;
  width: 38px;
  height: 38px;
  left: -39px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.customizer .color-box {
  height: 24px;
  width: 24px;
  margin: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;
}

.customizer .color-box.selected:after {
  content: "";
  border: 1px solid #7367f0;
  height: 30px;
  width: 30px;
  top: -3px;
  left: -3px;
  position: absolute;
  border-radius: 0.5rem;
}

.customizer .color-box.selected.border:after {
  top: -4px;
  left: -4px;
}

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;
}

.divider .divider-text {
  position: relative;
  display: inline-block;
  font-size: 0.9375rem;
  padding: 0 1rem;
}

.divider .divider-text i,
.divider .divider-text svg {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
}

.divider .divider-text:before,
.divider .divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  border-top: 1px solid #ebe9f1;
}

.divider .divider-text:before {
  right: 100%;
}

.divider .divider-text:after {
  left: 100%;
}

.divider.divider-left .divider-text {
  float: left;
  padding-left: 0;
}

.divider.divider-left .divider-text:before {
  display: none;
}

.divider.divider-left-center .divider-text {
  left: -25%;
}

.divider.divider-right .divider-text {
  float: right;
  padding-right: 0;
}

.divider.divider-right .divider-text:after {
  display: none;
}

.divider.divider-right-center .divider-text {
  right: -25%;
}

.divider.divider-dotted .divider-text:before,
.divider.divider-dotted .divider-text:after {
  border-style: dotted;
  border-width: 1px;
  border-top-width: 0;
  border-color: #ebe9f1;
}

.divider.divider-dashed .divider-text:before,
.divider.divider-dashed .divider-text:after {
  border-style: dashed;
  border-width: 1px;
  border-top-width: 0;
  border-color: #ebe9f1;
}

.timeline {
  padding: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
  padding-left: 2.5rem;
  border-left: 1px solid #ebe9f1;
}

.timeline .timeline-item:not(:last-child) {
  padding-bottom: 1.8rem;
}

.timeline .timeline-item .timeline-point {
  position: absolute;
  left: -0.85rem;
  top: 0;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #7367f0;
  background-color: #ffffff;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator {
  left: -0.412rem;
  top: 0.07rem;
  height: 12px;
  width: 12px;
  border: 0;
  background-color: #7367f0;
}

.timeline .timeline-item .timeline-point.timeline-point-indicator:before {
  content: "";
  background: rgba(115, 103, 240, 0.12);
  height: 20px;
  width: 20px;
  display: block;
  position: absolute;
  top: -0.285rem;
  left: -0.285rem;
  border-radius: 50%;
}

.timeline .timeline-item .timeline-point i,
.timeline .timeline-item .timeline-point svg {
  color: #7367f0;
  font-size: 0.85rem;
  vertical-align: baseline;
}

.timeline .timeline-item .timeline-event {
  position: relative;
  width: 100%;
  min-height: 4rem;
}

.timeline .timeline-item .timeline-event .timeline-event-time {
  font-size: 0.85rem;
  color: #b9b9c3;
}

.timeline .timeline-item:last-of-type {
  border-left-color: transparent !important;
}

.timeline .timeline-item:last-of-type:after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: 0;
  width: 1px;
  height: 100%;
  background: -webkit-linear-gradient(#ebe9f1, transparent);
  background: linear-gradient(#ebe9f1, transparent);
}

@media screen and (min-width: 0) {
  head {
    font-family: "xs 0px";
  }
  body:after {
    content: "xs - min-width: 0px";
  }
}

@media screen and (min-width: 544px) {
  head {
    font-family: "sm 544px";
  }
  body:after {
    content: "sm - min-width: 544px";
  }
}

@media screen and (min-width: 768px) {
  head {
    font-family: "md 768px";
  }
  body:after {
    content: "md - min-width: 768px";
  }
}

@media screen and (min-width: 992px) {
  head {
    font-family: "lg 992px";
  }
  body:after {
    content: "lg - min-width: 992px";
  }
}

@media screen and (min-width: 1200px) {
  head {
    font-family: "xl 1200px";
  }
  body:after {
    content: "xl - min-width: 1200px";
  }
}

head {
  clear: both;
}

head title {
  font-family: "xs 0px, sm 544px, md 768px, lg 992px, xl 1200px";
}

body:after {
  display: none;
}

*[data-usn-if] {
  display: none;
}

.select2-container {
  width: 100% !important;
  margin: 0;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
}

.select2-container--classic:focus,
.select2-container--default:focus {
  outline: none;
}

.select2-container--classic .select2-selection__choice,
.select2-container--default .select2-selection__choice {
  font-size: 0.9rem;
  margin-top: 6px !important;
}

.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single {
  min-height: 2.714rem;
  padding: 5px;
  border: 1px solid #d8d6de;
}

.select2-container--classic .select2-selection--single:focus,
.select2-container--default .select2-selection--single:focus {
  outline: 0;
  border-color: #7367f0 !important;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered
  i,
.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered
  svg,
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered
  i,
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered
  svg {
  font-size: 1.15rem;
  height: 1.15rem;
  width: 1.15rem;
  margin-right: 0.5rem;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b,
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 18px 14px, 18px 14px;
  background-repeat: no-repeat;
  height: 1rem;
  padding-right: 1.5rem;
  margin-left: 0;
  margin-top: 0;
  left: -8px;
  border-style: none;
}

.select2-container--classic.select2-container--open,
.select2-container--default.select2-container--open {
  box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
}

.select2-container--classic.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--single {
  border-color: #7367f0 !important;
  outline: 0;
}

.select2-container--classic.select2-container--focus,
.select2-container--default.select2-container--focus {
  outline: 0;
}

.select2-container--classic.select2-container--focus
  .select2-selection--multiple,
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: #7367f0 !important;
  outline: 0;
}

.select2-container--classic .select2-dropdown,
.select2-container--default .select2-dropdown {
  border-color: #d8d6de;
}

.select2-container--classic .select2-search--dropdown,
.select2-container--default .select2-search--dropdown {
  padding: 0.5rem;
}

.select2-container--classic .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none !important;
  border-radius: 0.357rem;
  border-color: #d8d6de;
  padding: 0.438rem 1rem;
}

.select2-container--classic .select2-selection--multiple,
.select2-container--default .select2-selection--multiple {
  min-height: 38px !important;
  border: 1px solid #d8d6de;
}

.select2-container--classic .select2-selection--multiple:focus,
.select2-container--default .select2-selection--multiple:focus {
  outline: 0;
  border-color: #7367f0 !important;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
  color: #ffffff;
  padding: 2px 5px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 6px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered
  li
  .select2-search__field,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li
  .select2-search__field {
  margin-top: 7px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  float: right;
  margin-left: 0.7rem;
  margin-right: 0.5rem;
  font-size: 0;
  display: inline-block;
  position: relative;
  line-height: 1rem;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove:before,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  background-size: 0.85rem;
  height: 0.85rem;
  width: 0.85rem;
  position: absolute;
  top: 22%;
  left: -4px;
}

.select2-container--classic .select2-selection--multiple i,
.select2-container--classic .select2-selection--multiple svg,
.select2-container--default .select2-selection--multiple i,
.select2-container--default .select2-selection--multiple svg {
  position: relative;
  top: 1px;
  margin-right: 0.5rem;
  height: 1.15rem;
  width: 1.15rem;
  font-size: 1.15rem;
  padding-left: 1px;
}

.select2-container--classic .select2-results__group,
.select2-container--default .select2-results__group {
  padding: 6px 9px;
  font-weight: 600;
}

.select2-container--classic .select2-results__option,
.select2-container--default .select2-results__option {
  padding: 0.428rem 1rem;
}

.select2-container--classic .select2-results__option[role="group"],
.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-selected="true"],
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #7367f0 !important;
  color: white !important;
}

.select2-container--classic .select2-results__option i,
.select2-container--classic .select2-results__option svg,
.select2-container--default .select2-results__option i,
.select2-container--default .select2-results__option svg {
  height: 1.15rem;
  width: 1.15rem;
  font-size: 1.15rem;
  margin-right: 0.5rem;
}

.select2-container--classic .select2-results__option--highlighted,
.select2-container--default .select2-results__option--highlighted {
  background-color: rgba(115, 103, 240, 0.12) !important;
  color: #7367f0 !important;
}

.select2-container--classic .select2-result-repository__avatar img,
.select2-container--default .select2-result-repository__avatar img {
  width: 50px;
}

.select2-container--classic .select-lg,
.select2-container--default .select-lg {
  min-height: 2.714rem !important;
  font-size: 1.2rem;
  margin-bottom: 0 !important;
  padding: 0.3rem 0.7rem;
}

.select2-container--classic
  .select-lg.select2-selection--single
  .select2-selection__rendered,
.select2-container--default
  .select-lg.select2-selection--single
  .select2-selection__rendered {
  padding-top: -0.1rem;
}

.select2-container--classic
  .select-lg.select2-selection--single
  .select2-selection__arrow,
.select2-container--default
  .select-lg.select2-selection--single
  .select2-selection__arrow {
  top: 0rem !important;
}

.select2-container--classic .select-lg.select2-selection--multiple,
.select2-container--default .select-lg.select2-selection--multiple {
  padding: 0 0.2rem;
}

.select2-container--classic
  .select-lg.select2-selection--multiple
  .select2-selection__rendered,
.select2-container--default
  .select-lg.select2-selection--multiple
  .select2-selection__rendered {
  padding-top: 0 !important;
}

.select2-container--classic
  .select-lg.select2-selection--multiple
  .select2-selection__rendered
  li,
.select2-container--default
  .select-lg.select2-selection--multiple
  .select2-selection__rendered
  li {
  font-size: 1.2rem;
}

.select2-container--classic
  .select-lg.select2-selection--multiple
  .select2-selection__rendered
  li
  .select2-search__field,
.select2-container--default
  .select-lg.select2-selection--multiple
  .select2-selection__rendered
  li
  .select2-search__field {
  margin-top: 10px;
}

.select2-container--classic
  .select-lg.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice,
.select2-container--default
  .select-lg.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  padding: 5px;
}

.select2-container--classic
  .select-lg.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove:before,
.select2-container--default
  .select-lg.select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove:before {
  top: 46%;
}

.select2-container--classic .select-sm,
.select2-container--default .select-sm {
  min-height: 2.142rem !important;
  padding: 0 0.2rem;
  font-size: 0.75rem;
  margin-bottom: 0 !important;
  line-height: 1.5;
}

.select2-container--classic
  .select-sm.select2-selection--single
  .select2-selection__arrow,
.select2-container--default
  .select-sm.select2-selection--single
  .select2-selection__arrow {
  top: -0.3rem !important;
}

.select2-container--classic .select-sm.select2-selection--multiple,
.select2-container--default .select-sm.select2-selection--multiple {
  line-height: 1.3;
}

.select2-container--classic
  .select-sm.select2-selection--multiple
  .select2-selection__rendered,
.select2-container--default
  .select-sm.select2-selection--multiple
  .select2-selection__rendered {
  padding: 3px;
}

.select2-container--classic
  .select-sm.select2-selection--multiple
  .select2-selection__rendered
  li,
.select2-container--default
  .select-sm.select2-selection--multiple
  .select2-selection__rendered
  li {
  font-size: 0.75rem;
  margin-top: 2px;
}

.select2-container--classic
  .select-sm.select2-selection--multiple
  .select2-selection__rendered
  li
  .select2-search__field,
.select2-container--default
  .select-sm.select2-selection--multiple
  .select2-selection__rendered
  li
  .select2-search__field {
  margin-top: 4px;
}

.select2-container--classic
  .select-sm.select2-selection--multiple
  .select2-selection__choice,
.select2-container--default
  .select-sm.select2-selection--multiple
  .select2-selection__choice {
  padding: 0 0.2rem;
}

.select2-container--classic
  .select-sm.select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove:before,
.select2-container--default
  .select-sm.select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove:before {
  top: 5%;
}

.select2-container--classic
  .select-sm.select2-selection--multiple
  .select2-search--inline
  .select2-search__field,
.select2-container--default
  .select-sm.select2-selection--multiple
  .select2-search--inline
  .select2-search__field {
  margin-top: 0;
}

.dark-layout .select2-container .select2-selection,
.dark-layout .select2-container .select2-search__field,
.dark-layout .select2-container .select2-selection__placeholder {
  background: #283046;
  border-color: #3b4253;
  color: #b4b7bd;
}

.dark-layout .select2-container .select2-selection__rendered {
  color: #b4b7bd;
}

.dark-layout .select2-container .select2-dropdown {
  background-color: #283046;
  border-color: #3b4253;
}

.dark-layout
  .select2-container
  .select2-selection--multiple
  .select2-selection__choice {
  background: rgba(115, 103, 240, 0.12) !important;
  color: #7367f0 !important;
  border: none;
}

.dark-layout
  .select2-container
  .select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove {
  color: #7367f0 !important;
}

.dark-layout
  .select2-container
  .select2-selection--multiple
  .select2-selection__choice__remove:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
}

.dark-layout .select2-container .select2-results__option[aria-disabled="true"] {
  color: #676d7d;
}

.dark-layout .select2-container .select2-selection__arrow b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b4b7bd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.dark-layout .select2-container--disabled .select2-selection {
  opacity: 0.5;
}

table.dataTable {
  width: 100% !important;
  border-collapse: collapse !important;
}

table.dataTable td,
table.dataTable th {
  padding: 0.72rem 1.5rem;
  vertical-align: middle;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  padding-right: inherit;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  color: #6e6b7b;
  width: 14px;
  height: 14px;
  content: "";
  right: 0.3rem;
  top: 0.5rem;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  color: #6e6b7b;
  width: 14px;
  height: 14px;
  content: "";
  right: 0.3rem;
  top: 1.3rem;
}

table.dataTable .dt-checkboxes-cell .custom-control {
  padding-left: 0;
  padding-right: 1.25rem;
}

div.dataTables_wrapper .dt-button-collection {
  background-color: #ffffff;
}

div.dataTables_wrapper .dt-button-collection > div[role="menu"] {
  text-align: left;
  box-shadow: 0 5px 16px -8px;
  min-width: 8rem;
}

div.dataTables_wrapper .dt-button-collection [class*="buttons-"]:focus {
  outline: 0;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  line-height: 16px;
}

div.dataTables_wrapper div.dataTables_filter label,
div.dataTables_wrapper div.dataTables_length label {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

div.dataTables_wrapper div.dataTables_filter select,
div.dataTables_wrapper div.dataTables_length select {
  background-position: calc(100% - 3px) 11px, calc(100% - 20px) 13px, 100% 0;
  padding: 0 0.8rem;
  width: 5rem;
  margin: 0 0.5rem;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.75rem;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

div.dataTables_wrapper .dataTables_info {
  padding-top: 1.25rem !important;
  color: #b9b9c3;
}

tr.group {
  background-color: #fafafc;
}

@media only screen and (max-width: 768px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  div.dataTables_wrapper div.dataTables_info {
    white-space: inherit;
  }
  div.dt-buttons {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 1rem;
  }
  div.dt-buttons button {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 575.98px) {
  .dtr-bs-modal .modal-body {
    padding-left: 0;
    padding-right: 0;
    overflow-x: scroll;
  }
  .dtr-bs-modal .table.dtr-details {
    margin: 0 auto 1rem;
  }
  .dtr-bs-modal .table.dtr-details td {
    padding-left: 0;
    padding-right: 0;
  }
  .dtr-bs-modal .table.dtr-details tr td:first-child {
    padding-left: 0.75rem;
  }
  div.dataTables_wrapper {
    overflow: scroll;
  }
}

/* Bootstrap Touchspin */
.bootstrap-touchspin.input-group {
  width: 8.4rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bootstrap-touchspin.input-group .form-control {
  padding: 5px;
  height: auto;
  border: 0;
  background-color: #f8f8f8;
  border-radius: 0.357rem !important;
  text-align: center;
  font-weight: 500;
}

.bootstrap-touchspin.input-group .form-control:focus {
  z-index: 1;
  box-shadow: none;
}

.bootstrap-touchspin .bootstrap-touchspin-injected {
  margin: 0 !important;
}

.bootstrap-touchspin .bootstrap-touchspin-injected.input-group-prepend {
  left: 12px;
  position: relative;
}

.bootstrap-touchspin .bootstrap-touchspin-injected.input-group-append {
  right: 12px;
  position: relative;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  padding: 0;
  min-width: 20px;
  min-height: 20px;
  border-radius: 0.357rem !important;
}

.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down i,
.bootstrap-touchspin
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-down
  svg,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up i,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up svg {
  height: 0.8rem;
  width: 0.8rem;
  font-size: 0.8rem;
  position: relative;
  top: -1px;
}

.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down,
.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up {
  background-color: rgba(34, 41, 47, 0.5) !important;
  cursor: default;
  opacity: 0.5;
}

.bootstrap-touchspin.input-group-lg {
  width: 9.375rem;
}

.bootstrap-touchspin.input-group-lg .touchspin.form-control {
  height: auto !important;
}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up {
  min-width: 24px;
  min-height: 24px;
}

.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down i,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down svg,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up i,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up svg {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
  top: -3px;
}

.bootstrap-touchspin.input-group-sm {
  width: 6.25rem;
}

.bootstrap-touchspin.input-group-sm .touchspin.form-control {
  height: auto !important;
}

.bootstrap-touchspin.input-group-sm
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-down,
.bootstrap-touchspin.input-group-sm
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-up {
  min-width: 16px;
  min-height: 16px;
}

.bootstrap-touchspin.input-group-sm
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-down
  i,
.bootstrap-touchspin.input-group-sm
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-down
  svg,
.bootstrap-touchspin.input-group-sm
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-up
  i,
.bootstrap-touchspin.input-group-sm
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-up
  svg {
  height: 0.6rem;
  width: 0.6rem;
  font-size: 0.6rem;
}

/* Number Type Input Box Scss for Touchspin - Remove arrow for firefox */
.bootstrap-touchspin.input-group input[type="number"] {
  -moz-appearance: textfield;
}

.dark-layout .bootstrap-touchspin.input-group .form-control {
  background-color: #161d31;
}

.dark-layout
  .bootstrap-touchspin.disabled-touchspin
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-down,
.dark-layout
  .bootstrap-touchspin.disabled-touchspin
  .bootstrap-touchspin-injected
  .bootstrap-touchspin-up,
.dark-layout
  .bootstrap-touchspin.disabled-touchspin
  .bootstrap-touchspin-injected
  .disabled-max-min {
  background-color: #b8c2cc !important;
  opacity: 1;
}

.dark-layout
  .bootstrap-touchspin
  .bootstrap-touchspin-injected
  .disabled-max-min {
  background-color: #b8c2cc !important;
  opacity: 1;
}
