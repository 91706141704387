/* login page css */
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 730px !important;
  margin: 0 auto;
}
.back-img {
  height: 100vh;
  width: 100%;
  background: url(../app-assets/images/pages/Image.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.forgot-password {
  color: #0059c1;
  font-size: 8px;
}

/* register page css */
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 800px !important;
}

.back-img {
  /* height: 100vh; */
  width: 100%;
  background: url(../app-assets/images/pages/Image.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.forgot-password {
  color: #0059c1;
  font-size: 8px;
}

.custom-control-snapchat
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #000000;
  background-color: #fffc00;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #000000;
  background-color: #fffc00;
}
/* 
#othermsg {
  display: none;
} */

.disabled-btn-main-wrapper {
  color: #ACB0B5;
    border-color: #E3E3E3;
    border-style: solid;
    cursor: not-allowed;
    background: #EBEBEB repeating-linear-gradient(45deg, #EBEBEB, #EBEBEB 2px, #F2F2F2 2px, #F2F2F2 8px);
}

.user-info-drp{
  background-color: #fff;
  border-radius: .286rem;
  box-shadow: 0 2px 20px 0 rgb(34 41 47 / 8%);
  padding: 1rem;
  position: absolute;
    top: 45px;
    width: 80%;
    /* text-align: center; */
    cursor: pointer
}
                     
.custom-drp-css-wrapper{
  position: relative;
}

.toppadding{
  top:52px !important;
}




/* bottomCard.js */

.cookie-overlay {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  background: #fff;
  z-index: 2051;
  line-height: 20px;
  font-size: 14px;
  border-radius: 6px;
  
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.btn-snapchat-cookie {
  background-color: #fffc00 !important;
  color: black !important;
  /* padding: 13px; */
  border-radius: 25px !important;
 }
 .btn-danger-cookie {
  border-color: #fb7373 !important ;
  background-color: #fb7373 !important;
  color: #fff !important;
  border-radius: 25px !important;
 }
 .close-cookies {
  cursor: pointer;
  }

  .capitalize {
    text-transform: capitalize;
  }