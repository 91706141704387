.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* below block for font family in complete website */
/* --------------------------------------------------- */
@font-face {
  font-family: "Program OT";
  src: url("./app-assets/fontFamily/Program-OT-Black-Italic.otf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Program OT";
  src: url("./app-assets/fontFamily/Program-OT-Black.otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Program OT";
  src: url("./app-assets/fontFamily/Program-OT-Bold-Italic.otf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Program OT";
  src: url("./app-assets/fontFamily/Program-OT-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Program OT";
  src: url("./app-assets/fontFamily/Program-OT-Medium-Italic.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Program OT";
  src: url("./app-assets/fontFamily/Program-OT-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Program OT";
  src: url("./app-assets/fontFamily/Program-OT-Regular-Italic.otf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Program OT";
  src: url("./app-assets/fontFamily/Program-OT-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Program OT";
}
/* ----------------------------------------------------- */
.app-content.content.page-main-div-wrapper {
  padding-top: 6%;
  padding-left: 1%;
  padding-right: 1%;
}
.loader-component {
  z-index: 40;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

img.image-loader-offers {
  width: 10%;
  border-radius: 50%;
  left: 50% !important;
  transform: translate(-5%, 0);
  position: absolute;
  z-index: 9999;
  top: 40%;
}

.fullscreenDiv {
  position: absolute;
  top: 40%;
  left: 6%;
  right: 0;
  text-align: center;
}

.grecaptcha-badge {
  display: none;
}
