/* .distribution-radio-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}
.distribution-checkbox-apps-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
} */
.no-padding {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.cms-content-styling * {
  font-size: 13px;
  color: #6e6b7b;
  font-weight: 400;
  line-height: 18.9px;
  letter-spacing: 0.14px;
}
