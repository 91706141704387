.header-navbar.floating-nav {
  position: fixed;
  right: 0;
  margin: 0rem;
  width: calc(100vw - (100vw - 100%));
  border-radius: 0.428rem;
  z-index: 1032;
}
.card {
  margin-bottom: 1rem !important;
}
select.form-control:not([multiple="multiple"]) {
  padding-right: 2.5rem !important;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  width: 245px !important;
}
.nav-pills .nav-link.active {
  border-color: #ffffff;
  box-shadow: 0 4px 18px -4px rgb(115 103 240 / 65%);
  border-radius: 25px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000000;
  background-color: #ffffff;
}
.table .card-header-bg th {
  background-color: #ffffef !important;
}
.badge-width-25 {
  width: 20px;
  height: 20px;
}
.badge-snapchat {
  background-color: #f8ec86;
}
.badge-snapchat-secondary {
  background-color: #b8b8b8;
}
.btn-snapchat-danger {
  background-color: #ff7575;
  color: black;
}
.table thead th,
.table tfoot th {
  vertical-align: top;
  text-transform: capitalize;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
}

.no-padding{
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
/* .addUserbtn{
  color: #000000;
  background-color: #ffffff;
  width: fit-content;
  padding: 7px 20px;
  font-size: 12px;
  margin: 0 auto;
  border-radius: 25px;
  box-shadow: 0px 0px 10px #2727271A;
  cursor: pointer;
} */