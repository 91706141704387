.header-navbar.floating-nav {
  position: fixed;
  right: 0;
  margin: 0rem;
  width: calc(100vw - (100vw - 100%));
  border-radius: 0.428rem;
  z-index: 1032;
}

.card {
  margin-bottom: 1rem !important;
}
select.form-control:not([multiple="multiple"]) {
  padding-right: 2.5rem !important;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  width: 245px !important;
}
.nav-pills .nav-link.active {
  border-color: #ffffff;
  box-shadow: 0 4px 18px -4px rgb(115 103 240 / 65%);
  border-radius: 25px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000000;
  background-color: #ffffff;
}
.table .card-header-bg th {
  background-color: #ffffef !important;
}
.card-header-bg {
  background-color: #cccccc !important;
}
.table thead th,
.table tfoot th {
  text-transform: capitalize;
}
.no-padding {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.fs-13 {
  font-size: 13px;
}

.cms-content-style * {
  color: #4b4b4b;
  /* font-weight: 400; */
}

/* .cms-content-style > p {
  display: flex;
  gap: "2px";
} */
a > button {
  background: #fffc00;
  color: black !important;
  border: 0px solid !important;
  border-radius: 24px !important;
  padding: 6px 16px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  box-shadow: 0px 1px 2px 0px #00000026 !important;
}

a > button:hover {
  color: #4b4b4b !important;
}

p > a {
  color: blue !important;
  text-decoration: underline !important;
}

details summary::marker {
  display: none !important;
}

summary,
summary * {
  list-style: none;
  color: black;
  font-size: 16px !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: space-between;
  transition: all;
  transition-duration: 300ms;
}

summary::after {
  content: url("../../app-assets/images/icons/angle-small-down.svg");
  background-size: contain;
  width: 20px;
  height: 20px;
  transition: all;
  transition-duration: 300ms;
}

details[open] summary::after {
  transform: rotate(180deg);
  transition: all;
  transition-duration: 300ms;
}

details {
  display: flex !important;
  flex-direction: column;
  gap: 20px;
  padding-top: 28px;
  padding-bottom: 8px;
  padding-left: 14px;
  border-bottom: 1px solid #ebe9f1;
  transition: all;
  transition-duration: 300ms;
}

details > :nth-child(2) {
  margin-bottom: 2px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #6e6b7b;
}

@keyframes details-show {
  from {
    opacity: 0;
    transform: var(--details-translate, translateY(-0.5em));
  }
}
details[open] > *:not(summary) {
  animation: details-show 300ms ease-in-out;
}
